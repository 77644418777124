import Button from 'antd/lib/button';
import styled from 'styled-components';

import { IButtonProps } from './types';
import { borderRadiusBase, buttonSizes, colors } from 'styles/variables.module';

export const ButtonStyleWrapper = styled(Button)<IButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size = 'small', type = 'default', danger = false }) => {
    const defaultTypeBgColor = `${
      type === 'default' && `background: ${colors.gray_3};`
    }`;

    const dangerBgColor = `${danger && `background: ${colors.red_6};`}`;

    return `&& {
     height: ${buttonSizes[size].height};
     border-radius: ${borderRadiusBase};
     border: none;
     
     > span {
       font-size: ${buttonSizes[size].fontSize};
     }
     ${defaultTypeBgColor}

     ${dangerBgColor}
    }`;
  }};
`;
