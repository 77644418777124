import { FC } from 'react';

import {
  BodyTextEnum,
  FontWightEnum
} from 'common/globalTypes/enums/typography';
import { IDisplayTextProps } from './types';
import { DisplayTextWrapper } from './styles';

const DisplayHeading: FC<IDisplayTextProps> = ({
  text,
  level = 1,
  size = BodyTextEnum.base,
  defaultMargin = true,
  fontWeight = FontWightEnum.bold,
  className = '',
  children,
  ...restProps
}) => {
  return (
    <DisplayTextWrapper
      level={level}
      size={size}
      className={`${className} ${!defaultMargin ? 'deleteMargin' : ''}`}
      fontWeight={fontWeight}
      {...restProps}
    >
      {text || children}
    </DisplayTextWrapper>
  );
};

export default DisplayHeading;
