import { FC } from 'react';
import { Flex } from 'antd';

import DisplayText from 'components/atoms/DisplayText';
import BodyText from 'components/atoms/BodyText';
import { INotFoundResultProps } from './types';

const NotFoundResult: FC<INotFoundResultProps> = ({ extra }) => {
  return (
    <Flex justify="center" align="center" vertical gap={4}>
      <DisplayText
        text="404 Not Found"
        level={1}
        size="extraLarge"
        fontWeight="bold"
      />
      <BodyText
        text="Sorry, the page you visited does not exist."
        size="base"
        fontWeight="medium"
      />
      {extra}
    </Flex>
  );
};

export default NotFoundResult;
