'use client';

import { NotFoundStyleWrapper, ResultExtraStyleWrapper } from './styles';
import useRouteWithLanguage from 'hooks/common/useRouteWithLanguage';
import Button from 'components/atoms/Button';
import NotFoundResult from 'components/molecules/NotFoundResult';

const NotFound = () => {
  const { push } = useRouteWithLanguage();

  const handleRedirectHomePage = () => {
    push('/');
  };

  return (
    <>
      <NotFoundStyleWrapper>
        <NotFoundResult
          extra={
            <ResultExtraStyleWrapper>
              <Button
                onClick={handleRedirectHomePage}
                size="large"
                type="primary"
                text="Back Home"
              />
            </ResultExtraStyleWrapper>
          }
        />
      </NotFoundStyleWrapper>
    </>
  );
};

export default NotFound;
