import styled from 'styled-components';

import { sizes } from 'styles/variables.module';

export const NotFoundStyleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ResultExtraStyleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${sizes.spXl};
`;
