import { usePathname, useRouter } from 'next/navigation';

const useRouteWithLanguage = () => {
  const router = useRouter();
  const pathname = usePathname();

  const push = (pathName: string) => {
    router.push(pathName);
  };

  const replace = (pathName: string) => {
    router.replace(pathName);
  };

  return {
    ...router,
    pathname,
    replace,
    push
  };
};

export default useRouteWithLanguage;
