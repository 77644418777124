'use client';
import { FC } from 'react';

import { ButtonStyleWrapper } from './styles';
import { IButtonProps } from './types';

const Button: FC<IButtonProps> = ({
  size = 'small',
  shape,
  danger = false,
  icon,
  onClick,
  loading,
  text,
  href = '',
  block,
  target = '_blank',
  children,
  type,
  style,
  disabled
}) => {
  const buttonProps = {
    size,
    style,
    shape,
    danger,
    type,
    icon,
    block,
    disabled,
    loading
  };

  if (href) {
    return (
      <a href={href} target={target}>
        <ButtonStyleWrapper {...buttonProps}>
          {text || children}
        </ButtonStyleWrapper>
      </a>
    );
  }

  return (
    <ButtonStyleWrapper onClick={onClick} {...buttonProps}>
      {text || children}
    </ButtonStyleWrapper>
  );
};

export default Button;
