import Typography from 'antd/lib/typography';
import styled from 'styled-components';

import {
  BodyTextEnum,
  FontWightEnum
} from 'common/globalTypes/enums/typography';
import { typography } from 'styles/variables.module';
import { IDisplayTextProps } from './types';

const { Title } = Typography;
const shouldForwardProp = (prop: string) =>
  ['text', 'children', 'className'].includes(prop);

export const DisplayTextWrapper = styled(Title)
  .withConfig({
    shouldForwardProp
  })
  .attrs<IDisplayTextProps>(({ level }) => ({
    as: `h${level}`
  }))<IDisplayTextProps>`
  ${({
    size = BodyTextEnum.base,
    fontWeight = FontWightEnum.regular,
    ellipsisText,
    margin = 0
  }) => `&& {
      font-size: ${typography.bodyText.size[size]};
      font-weight: ${typography.fontWeight[fontWeight]};
      line-height: ${typography.bodyText.lineHeight[size]};
      letter-spacing: ${typography.bodyText.letterSpacing[size]}};
      margin: ${margin} !important;
      
      ${
        ellipsisText &&
        `  -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          vertical-align: middle;
          width: 100%  
          `
      }
  }`};
`;
